<template>
  <div>
    <component
      :is="qmoduleBase.getQcomponent(toPascalCase(qcomponent.componentType))"
      v-for="(qcomponent, qcomponentIndex) in route.meta.menu.module
        .qcomponents"
      :key="'component-index-' + qcomponentIndex"
      v-bind="qcomponent"
      :entity="
        qcomponent.entities && qcomponent.entities.length > 0
          ? qcomponent.entities[0]
          : ''
      "
      :menu="route.meta.menu"
      :module="route.meta.menu.module"
      :component-id="qcomponent.id"
      :stack="qmoduleBase.moduleStack"
    />
  </div>
</template>
<script setup>
import { useQmoduleBase } from "~/composables/useQmoduleBase";
import { useQmoduleProps } from "~/composables/useQmoduleProps";

const props = defineProps(useQmoduleProps());
const qmoduleBase = useQmoduleBase(props);
const route = useRoute();
route.meta.menu.module.id = qmoduleBase.moduleId;
</script>
